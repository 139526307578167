// React
import { Link } from 'react-router-dom';

// Ionic
import { IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';

// Styles
import './Home.scss';

// Assets
import nft6 from './../assets/art/6@3x.png';
import nft73 from './../assets/art/73@3x.png';
import nft170 from './../assets/art/170@3x.png';
import nft281 from './../assets/art/281@3x.png';

import { logoTwitter } from 'ionicons/icons';
import { logoInstagram } from 'ionicons/icons';

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="row title mb0">
          <div className="column small-12 tc animate__animated animate__fadeIn">
            <div className="o">
            <video className="relative z-1" loop muted autoPlay playsInline>
                  <source 
                          src="http://brokenreality.com/wp-content/uploads/2021/12/souloracles-o-1.mp4"
                          type="video/mp4" />
              </video>
            </div>
            <h1 className="glow top relative z-2">Soul Oracles</h1>
          </div>
        </div>
        <section className="top-bg">
          <div className="row">
            <div className="column small-12 tc">
              <p className="f2">Unique NFT anomalies generated to guide you through eternity.</p>
            </div>
          </div>
          <div className="row mt6 mb5">
            <div className="column small-12 medium-4 tc">
              {/* <img src={nft6} alt="" /> */}
              <video loop muted autoPlay playsInline>
                  <source 
                          src="https://brokenreality.com/wp-content/uploads/2021/12/GM2022-01.mp4"
                          type="video/mp4" />
              </video>
            </div>
            <div className="column small-12 medium-4 tc">
              <video loop muted autoPlay playsInline>
                  <source 
                          src="https://brokenreality.com/wp-content/uploads/2021/12/GM2022-02.mp4"
                          type="video/mp4" />
              </video>
            </div>
            <div className="column small-12 medium-4 tc">
              <video loop muted autoPlay playsInline>
                  <source 
                          src="https://brokenreality.com/wp-content/uploads/2021/12/GM2022-03.mp4"
                          type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="row mt6 mb4">
            <div className="column small-12 tc">
              <p className="f2">Behold, you are in the presence of divine&nbsp;oddities.</p>
              <p className="f2">Oracles that speak of wisdom and&nbsp;prophecy.</p>
              <p className="f2">~</p>
              <p className="f2">Creations that protect and restore&nbsp;the&nbsp;soul.</p>
              <p className="f2">For it is up to you, to be part of the greatest story ever&nbsp;told.</p>
            </div>
          </div>
        </section>

        <section className="mt5">
          <div className="row">
            <div className="column small-12 tc">
              <h2 className="glow">Traits</h2>
            </div>
            <div className="column small-12 tc mt0">
              <p className="f2 mv5">\\&nbsp;&nbsp;&nbsp;<span className="b">Background</span>&nbsp;&nbsp;&nbsp;//<br />One of three shapes defining each oracle's connection to the&nbsp;soul.</p>
              <p className="f2 mv5">\\&nbsp;&nbsp;&nbsp;<span className="b">Body</span>&nbsp;&nbsp;&nbsp;//<br />Posture, presentation, and kinetic base for each&nbsp;oracle. </p>
              <p className="f2 mv5">\\&nbsp;&nbsp;&nbsp;<span className="b">Head</span>&nbsp;&nbsp;&nbsp;//<br />The primary interface between you and your&nbsp;oracle.</p>
              <p className="f2 mv5">\\&nbsp;&nbsp;&nbsp;<span className="b">Accents</span>&nbsp;&nbsp;&nbsp;//<br />Ornaments, orbs, and particles to channel additional&nbsp;power.</p>
            </div>
          </div>
          <div className="row mt5">
            <div className="column small-12 tc">
              <h2 className="glow">Soul&nbsp;Oracle #281</h2>
            </div>
            <div className="column small-12 tc mt5 mb6">
              <div className="row">
                <div className="column small-12 large-5 flex justify-center items-center pb5">
                  <img src={nft281} alt="" />
                </div>
                <div className="column small-12 large-7 tl ph4">
                  <p className="f3 mb4 lh-copy"><span className="b">Background&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;Circle</span><br />A representation of infinity and totality. Timelessness, one self, and all cyclical movement. A shape whos center is everywhere and circumference is nowhere. - Hermes&nbsp;Trismegistus</p>
                  <p className="f3 mv4 lh-copy"><span className="b">Body&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;Female</span><br />The body and frame of a cross dimensional feminine window walker. A humanoid articulation with a lean and elegant&nbsp;posture.</p>
                  <p className="f3 mv4 lh-copy"><span className="b">Head&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;Mother</span><br />The head of a being representing the wisdom of past and future generations. A guardian of high expectations and limitless&nbsp;love.</p>
                  <p className="f3 mt4 lh-copy"><span className="b">Accents&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;</span><br />Antiparticle Channels<br />Six conductive pathways used to generate three consecutive charges. A balanced distribution of positrons and electrons working together, never&nbsp;colliding.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row bottom-bg flex-column justify-start">
            <div className="column small-12 tc">
              <h2 className="glow">Roadmap</h2>
            </div>
            <div className="column small-12 tc mt0 mb6">
              <p className="f2 mv5">2021-12&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;Inception - The first generation of soul oracles are&nbsp;born.</p>
              <p className="f2 mv5">2022-...&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;The Great Mint - Find your oracle and create its immutable&nbsp;record.</p>
              <p className="f2 mv5">2022-...&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;Color Your Soul - Transform your collectible with&nbsp;color.</p>
              <p className="f2 mv5">2022-...&nbsp;&nbsp;&nbsp;//&nbsp;&nbsp;&nbsp;...</p>
            </div>
          </div>
        </section>
        <section className="mb3 tc">
          <p className="f4 ttu tracked-mega lh-copy">This disturbance is brought to you by&nbsp;<Link to={{ pathname: `https://brokenreality.com`}} target={`_blank`} className="white">Brokenreality</Link></p>
        </section>


        <section className="mv5 tc">
          <Link to={{ pathname: `https://twitter.com/souloracles_nft`}} target={`_blank`} className="white f2 mh2"><IonIcon icon={logoTwitter} /></Link>
          <Link to={{ pathname: `https://instagram.com/souloracles`}} target={`_blank`} className="white f2 mh2"><IonIcon icon={logoInstagram} /></Link>
        </section>


        <section className="mb6 tc">
          <p className="f4 ttu tracked-mega">v2022.1.1</p>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default Home;
